import React, { useState } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import css from './AboutPage.module.css';
import SideNav from '../../components/SideNav/SideNav';
import Modal from '../../components/Modal/Modal';
import SimpleSlider from '../../components/SlideShow/SlideShow';
import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

import './mui.css';
import { useSelector } from 'react-redux';

const AboutPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const { isAuthenticated } = useSelector(state => state.Auth);

  const pageName = ['Acerca del equipo'];

  // prettier-ignore
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function openModal1() {
    setOpen(true);
  }

  function openModal2() {
    setOpenModal(true);
  }

  function openModal3() {
    setIsOpen(true);
  }

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < 768;
  const {currentUser} = useSelector(state=>state.user)

  return (
    <StaticPage
      title="Nuestra Misión"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Acerca',
        name: 'Acerca',
      }}
      description="Nuestra Misión"
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer pageName={pageName} />
          <div className={css.sideNav}>
            <SideNav currentUser={currentUser}/>
          </div>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={isAuthenticated ? css.contentWrapper: css.noWrapper}>
            <div className={css.fixedWidthContainer}>
              <div className={css.mobile}>
                <p className={css.mobileText}>Sobre nosotros</p>
              </div>
              <div className={css.getToKnowSec}>
                <div className={css.getToKnowLeft}>
                  <span className={css.pattern1}>
                    <svg
                      width="54"
                      height="75"
                      viewBox="0 0 54 75"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="3" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="72" r="3" fill="#E9E9E9" />
                    </svg>
                  </span>
                  <SimpleSlider />{' '}
                  <span className={css.pattern2}>
                    <svg
                      width="54"
                      height="75"
                      viewBox="0 0 54 75"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="3" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="3" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="3" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="19" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="35" cy="72" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="20" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="38" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="55" r="3" fill="#E9E9E9" />
                      <circle cx="51" cy="72" r="3" fill="#E9E9E9" />
                    </svg>
                  </span>
                </div>
                <div className={css.getToKnowRight}>
                  <h2>ACERCA</h2>
                  {/* <p className={css.text1}>QUIENES SOMOS</p> */}
                  <p className={css.text2}>Conócenos y descubre qué nos motiva</p>
                  <p className={css.text3}>
                    <strong>Réntalo®</strong> es la plataforma de alquiler de autos más reciente,
                    segura y rentable.
                    <br />
                    Réntalo simplifica la vida de las personas y eficientiza el uso de los recursos compartidos empleando herramientas como: pasarelas de pagos,
                    sistema de verificación biométrica para validar a los usuarios, firma digital del contrato y hasta la contratación de una cobertura de riesgo
                    internacional que garantiza la inversión del propietario. Todo a través de una experiencia de usuario amigable en nuestra plataforma.
                    <br />
                    <br /> Creamos este producto para brindar mejores oportunidades comerciales a
                    los propietarios de vehículos y empresas de alquiler de automóviles, para que
                    puedan generar más ganancias con sus automóviles.
                    <br />
                    <br /> Réntalo® es uno de los proyectos de base tecnológica que incuba Start Lab
                    de Altice Dominicana. Realizado en 2019 en la ciudad de Santiago y patrocinado
                    por La Aurora, S.A.
                    <br />
                    <br /> En Réntalo® tenemos como objetivo dinamizar el mercado de alquiler de
                    vehículos y de esta manera incrementar el ingreso de divisas y brindar seguridad
                    a nuestros clientes.
                    <br />
                    <br /> Queremos unir a las personas, construir una comunidad que desee crear un
                    mejor entorno y utilizar los activos de manera más eficiente.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={css.missionVision}>
            <div className={css.fixedWidthContainer}>
              <button onClick={openModal1} className={css.dottedButton}>
                <span>
                  Nuestra <strong>Misión</strong>
                </span>
              </button>{' '}
              <button onClick={openModal2} className={css.dottedButton}>
                <span>
                  Nuestra <strong>Visión</strong>
                </span>
              </button>{' '}
              <button onClick={openModal3} className={css.dottedButton}>
                <span>
                  Nuestros <strong>Valores</strong>
                </span>
              </button>
            </div>
            <Modal
            id="NuestraVisión"
              isOpen={open}
              onClose={() => {
                setOpen(false);
              }}
              onManageDisableScrolling={() => {}}
              title="Nuestra Misión"
            >
              <div className={css.aboutModalContent}>
                <p>
                Eficientizar el uso de los recursos compartidos a través de la tecnología. {' '}
                </p>
              </div>{' '}
            </Modal>

            <Modal
              isOpen={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              onManageDisableScrolling={() => {}}
              title="Nuestra Visión"
            >
              <div className={css.aboutModalContent}>
                <p>
                Proveer herramientas tecnológicas y espacios digitales confiables de fácil uso, para propiciar el intercambio de valor entre usuarios de Latinoamérica.{' '}
                </p>
              </div>{' '}
            </Modal>

            <Modal
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              onManageDisableScrolling={() => {}}
              title="Nuestros Valores"
              isLargeModal={true}
            >
              <div className={css.benefits}>
                <div className={css.boxWrapper}>
                  <h2>Bienestar</h2>
                  <p className={css.text4}>
                    Velamos por el desarrollo y protección de nuestros clientes, apostando a la
                    magia relacional que surge al compartir recursos con otros, a la vez que
                    incrementan su patrimonio.
                  </p>
                </div>{' '}
                <div className={css.boxWrapper}>
                  <h2>Innovación</h2>
                  <p className={css.text4}>
                    Somos creativos, trabajamos con entusiasmo y optimismo, buscando nuevas ideas
                    que impacten y transformen la cadena de valor de la Empresa.
                  </p>
                </div>
                <div className={css.boxWrapper}>
                  <h2> Transparencia</h2>
                  <p className={css.text4}>
                    Trabajamos en confianza, de forma abierta, en armonía, compartiendo
                    responsabilidades, y alineados hacia el propósito que nos guía.
                  </p>
                </div>
                <div className={css.boxWrapper}>
                  <h2>Cooperación</h2>
                  <p className={css.text4}>
                    Trabajamos en equipo para lograr los objetivos y resultados clave de la Empresa.
                    Medimos lo que realmente importa, de manera consciente. Potenciamos la sinergia
                    y la inteligencia colectiva.
                  </p>
                </div>
                <div className={css.boxWrapper}>
                  <h2>Empatía</h2>
                  <p className={css.text4}>
                    Nos ocupamos de atender las necesidades de nuestros clientes, internos y
                    externos. Enfocándonos en sus causas e implicaciones personales, y siendo
                    asertivos en las interacciones con ellos.
                  </p>
                </div>
              </div>
            </Modal>
          </div>
          <div className={css.ourBenefitsSec}>
            <h2 className={css.title}>
              ¿Quién se beneficia de<span className={css.orangeText}>Réntalo</span>y cómo?
            </h2>
            <div className={css.benefits}>
              <div className={css.boxWrapper}>
                <h2>Personas que necesitan automóviles</h2>
                <p className={css.text4}>
                  Réntalo® ofrece a los conductores una alternativa perfecta para moverse sin tener
                  que poseer un vehículo. Hay cientos para elegir y disfrutar de un servicio rápido
                  y seguro con una experiencia de usuario amigable a través de sus teléfonos
                  inteligentes.
                </p>
              </div>{' '}
              <div className={css.boxWrapper}>
                <h2>Propietarios de automóviles</h2>
                <p className={css.text4}>
                La propiedad de un automóvil puede ser costosa. ¿Por qué no hacer algo de dinero extra para mantenerlo cuando tu auto está disponible? Y reducir los costos de propiedad pagando su préstamo. Todos los vehículos están asegurados y usted tendrá el control completo de sus tarifas, quién conduce tu automóvil y cuándo.
                </p>
              </div>
              <div className={css.boxWrapper}>
                <h2> Tránsito de las ciudades</h2>
                <p className={css.text4}>
                Mejoramos la movilidad al aliviar la congestión y facilitar el uso del transporte público. Los coches están parqueados una media de 22 horas al día. Cuando compartimos autos, no necesitamos tantos en el camino, lo que significa menos accidentes, menos tráfico y menos espacio requerido para estacionar.
                </p>
              </div>
              <div className={css.boxWrapper}>
                <h2>¡Todos a largo plazo!</h2>
                <p className={css.text4}>
                  Con el medio ambiente sufriendo un poco más cada año, no hay mejor momento para
                  actuar que ahora. Estamos poniendo nuestro granito de arena contigo para lograr
                  juntos un planeta más limpio, más verde y más sostenible al reducir el número de
                  vehículos activos en nuestras ciudades y honrar nuestra hermosa isla.
                </p>
              </div>
            </div>
          </div>
          <div className={css.talkToUsSec}>
            <div className={css.leftSection}>
              {' '}
              <p className={css.contactText1}>HÁBLANOS</p>
              <p className={css.contactText2}>
                ¡Pregúntenos cualquier cosa y estaremos más que felices de responderle!
              </p>
              <p className={css.contactText3}>
                Puedes comunicarte con nosotros enviándonos un mensaje al numero +1 (829) 657-5069 o
                llámenos al +1 (829) 657-5069 para una reunión personalizada.
              </p>
            </div>
            <div className={css.rightSection}>
              <a
                className={css.contactButton}
                href={
                  !isMobileLayout
                    ? 'https://wa.me/18296575069?text=Estoy%20interesado%20en%20Réntalo'
                    : ' https://api.whatsapp.com/send?phone=+18296575069'
                }
                target="_blank"
              >
                <span>
                  <span className={css.whatsapp}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M8.88595 7.16985C9.06891 7.17475 9.27175 7.18465 9.46474 7.61303C9.59271 7.89821 9.80829 8.42321 9.9839 8.85087C10.1206 9.18366 10.233 9.45751 10.2611 9.51356C10.3254 9.64156 10.365 9.78926 10.2809 9.96156C10.271 9.98188 10.2617 10.0013 10.2526 10.02C10.1852 10.16 10.1372 10.2597 10.0237 10.3899C9.97709 10.4435 9.9285 10.5022 9.88008 10.5607C9.79494 10.6636 9.71035 10.7658 9.63785 10.838C9.50924 10.9659 9.37563 11.1039 9.52402 11.3599C9.6725 11.6159 10.1919 12.4579 10.9587 13.1373C11.783 13.8712 12.4998 14.1805 12.8622 14.3368C12.9325 14.3672 12.9895 14.3918 13.0313 14.4126C13.2886 14.5406 13.4419 14.5209 13.5903 14.3486C13.7388 14.1762 14.2334 13.6001 14.4066 13.3441C14.5748 13.0881 14.7479 13.1275 14.9854 13.2161C15.2228 13.3047 16.4892 13.9251 16.7464 14.0531C16.7972 14.0784 16.8448 14.1012 16.8889 14.1224C17.0678 14.2082 17.1895 14.2665 17.2411 14.3535C17.3054 14.4618 17.3054 14.9739 17.0927 15.5746C16.8751 16.1752 15.8263 16.7513 15.3514 16.7956C15.3064 16.7999 15.2617 16.8053 15.2156 16.8108C14.7804 16.8635 14.228 16.9303 12.2596 16.1555C9.83424 15.2018 8.23322 12.8354 7.90953 12.357C7.88398 12.3192 7.86638 12.2932 7.85698 12.2806L7.8515 12.2733C7.70423 12.0762 6.80328 10.8707 6.80328 9.62685C6.80328 8.43682 7.38951 7.81726 7.65689 7.53467C7.67384 7.51676 7.6895 7.50021 7.70366 7.48494C7.94107 7.22895 8.21814 7.16495 8.39125 7.16495C8.56445 7.16495 8.73756 7.16495 8.88595 7.16985Z"
                        fill="#ffffff"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.18418 21.3314C2.10236 21.6284 2.37285 21.9025 2.6709 21.8247L7.27824 20.6213C8.7326 21.409 10.37 21.8275 12.0371 21.8275H12.0421C17.5281 21.8275 22 17.3815 22 11.9163C22 9.26735 20.966 6.77594 19.0863 4.90491C17.2065 3.03397 14.7084 2 12.042 2C6.55607 2 2.08411 6.44605 2.08411 11.9114C2.08348 13.65 2.5424 15.3582 3.41479 16.8645L2.18418 21.3314ZM4.86092 17.2629C4.96774 16.8752 4.91437 16.4608 4.71281 16.1127C3.97266 14.8348 3.58358 13.3855 3.58411 11.9114C3.58411 7.28158 7.37738 3.5 12.042 3.5C14.3119 3.5 16.4296 4.37698 18.0281 5.96805C19.6248 7.55737 20.5 9.66611 20.5 11.9163C20.5 16.5459 16.7068 20.3275 12.0421 20.3275H12.0371C10.6206 20.3275 9.22863 19.9718 7.99266 19.3023C7.65814 19.1211 7.26726 19.0738 6.89916 19.17L4.13676 19.8915L4.86092 17.2629Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </span>
                  <a
                    href={
                      !isMobileLayout
                        ? 'https://web.whatsapp.com/send?phone=number&text=+18296575069&app_absent=0'
                        : ' https://api.whatsapp.com/send?phone=+18296575069'
                    }
                    target="_blank"
                  >
                    Conversemos
                  </a>
                </span>
                <span className={css.arrow}>
                  <svg width="14px" height="15px" viewBox="0 0 14.62 15.242">
                    <g
                      id="Icon_feather-arrow-right"
                      data-name="Icon feather-arrow-right"
                      transform="translate(0.75 1.061)"
                    >
                      <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M7.5,18H20.62"
                        transform="translate(-7.5 -11.44)"
                        fill="none"
                        stroke="#ffffff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M18,7.5l6.56,6.56L18,20.62"
                        transform="translate(-11.44 -7.5)"
                        fill="none"
                        stroke="#ffffff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
